<template>
  <div class="compare-container rounded-[10px] bg-white p-20">
    <div class="compare-header flex justify-between">
      <div class="flex items-center">
        <i class="iconfont icon-zoushi mr-5 text-20 text-gray"></i>
        <span>{{ $t('饰品走势') }}</span>
      </div>
      <div class="">
        <el-select v-model="mode" value-key="" placeholder="" size="large" style="width: 140px; height: 40px" @change="modeChange">
          <el-option :label="$t('饰品大类型')" :value="'big'"></el-option>
          <el-option :label="$t('饰品类型')" :value="'category'"></el-option>
        </el-select>
      </div>
    </div>
    <div class="compare-content">
      <el-table :data="itemTrendData.list" stripe height="402">
        <el-table-column prop="a" label="日期">
          <template #header>
            <div class="flex items-center justify-between">
              <span>{{ $t('饰品名称') }}</span>
              <el-popover v-model:visible="showpopover" width="180" trigger="click">
                <template #default>
                  <el-checkbox-group v-model="selectedType" @change="query.parentKeys = selectedType">
                    <el-checkbox v-for="item in topTypeList" :key="item.value" :label="item.name" :value="item.value" />
                  </el-checkbox-group>

                  <div class="flex justify-between">
                    <el-button
                      type="default"
                      size="default"
                      @click="selectedType = ['CSGO_Type_Rifle', 'Type_Hands', 'CSGO_Type_Knife', 'CSGO_Type_Pistol', 'CSGO_Type_SMG', 'CSGO_Type_Shotgun']"
                      >{{ $t('重置') }}</el-button
                    >
                    <el-button type="primary" size="default" @click="getList()">{{ $t('确认') }}</el-button>
                  </div>
                </template>
                <template #reference>
                  <i v-show="mode === 'category'" class="iconfont icon-shaixuan cursor-pointer text-18 text-gray"></i>
                </template>
                <!-- <el-button slot="reference">content</el-button> -->
              </el-popover>
            </div>
          </template>
          <template #default="scope">
            <div class="flex items-center">
              <rankLabel :rank="scope.$index + 1" class="mr-10"></rankLabel>
              <div v-show="itemTrendData.list && itemTrendData.list.length > 0">
                <img v-if="otherMode === 'big'" class="img1 h-39 w-52" :src="`https://cdn.ok-skins.com/steam-stock/new-index/${scope.row.typeval}.webp`" alt="" />
                <img v-if="otherMode === 'category'" class="img2 h-39 w-52" :src="`https://cdn.ok-skins.com/steam-stock/category/${scope.row.nameEn}.png`" alt="" />
              </div>
              <span>{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="b">
          <template #header>
            <div class="flex justify-between">
              <span>{{ $t('涨跌幅度') }}</span>
              <div class="flex cursor-pointer items-center" @click="changeSort">
                <i class="iconfont icon-paixu" :class="[sort === 'desc' ? 'text-blue' : 'text-gray']"></i>
                <i class="iconfont icon-paixu rotate-180" :class="[sort === 'asc' ? 'text-blue' : 'text-gray']" style="margin-left: -9px"></i>
              </div>
            </div>
          </template>
          <template #default="scope">
            <div class="flex">
              <div class="risefall flex items-center" :class="scope.row.sellPriceDiffRatio > 0 ? 'rise' : 'fall'">
                <i class="iconfont icon-shangsheng mr-5 text-14" :class="scope.row.sellPriceDiffRatio > 0 ? '' : 'rotate-180'"></i>
                <span>{{ needPlus(scope.row.sellPriceDiffRatio) }}{{ scope.row.sellPriceDiffRatio }}%</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-end">
        <a :href="localePath('/trend')" class="mt-10 text-gray">{{ $t('查看更多') }}>></a>
      </div>
    </div>
  </div>
</template>

<script setup>
import rankLabel from '~/components/common/rankLabel.vue'
import { newIndexItemNameTrend } from '~/api/steam'
const mode = ref('big')
const otherMode = ref('big')
const sort = ref('desc')
const { t } = useI18n()
const $t = t

const showpopover = ref(false)
const { data: itemTrend } = await newIndexItemNameTrend({ maxSize: 1000, sortType: sort.value })
const itemTrendData = ref(itemTrend)
const topTypeList = ref([
  { name: $t('步枪'), value: 'CSGO_Type_Rifle' },
  { name: $t('手套'), value: 'Type_Hands' },
  {
    name: $t('匕首'),
    value: 'CSGO_Type_Knife'
  },
  {
    name: $t('手枪'),
    value: 'CSGO_Type_Pistol'
  },
  {
    name: $t('冲锋枪'),
    value: 'CSGO_Type_SMG'
  },
  {
    name: $t('霰弹枪'),
    value: 'CSGO_Type_Shotgun'
  }
])
const selectedType = ref(['CSGO_Type_Rifle', 'Type_Hands', 'CSGO_Type_Knife', 'CSGO_Type_Pistol', 'CSGO_Type_SMG', 'CSGO_Type_Shotgun'])
const query = ref({
  maxSize: 1000,
  sortType: sort.value
})
// for (let i = 0; i < itemTrendData.value.list.length; i++) {
//   topTypeList.value.push({
//     name: itemTrendData.value.list[i].name,
//     value: itemTrendData.value.list[i].typeval
//   })
//   selectedType.value.push(itemTrendData.value.list[i].typeval)
// }

const changeSort = () => {
  console.log('changeSort')
  if (sort.value === 'desc') {
    sort.value = 'asc'
    query.value.sortType = 'asc'
  } else if (sort.value === 'asc') {
    query.value.sortType = ''
    sort.value = ''
  } else {
    sort.value = 'desc'
    query.value.sortType = 'desc'
  }
  getList()
}

const modeChange = async val => {
  console.log('modeChange', val)

  if (mode.value === 'big') {
    query.value.parentKeys = []
  } else {
    query.value.parentKeys = JSON.parse(JSON.stringify(selectedType.value))
    // mode.value = 'big'
  }
  await getList()
  otherMode.value = val
}

const getList = async () => {
  showpopover.value = false
  const { data } = await newIndexItemNameTrend(query.value)
  itemTrendData.value = data
}

const needPlus = num => {
  return num > 0 ? '+' : ''
}
</script>

<style lang="scss">
.compare-container {
  .compare-header {
    .el-select__wrapper {
      border-radius: 10px !important;
    }
  }
  .risefall {
    // display: inline-block;
    border-radius: 10px;
    height: 30px;
    padding: 5px;
    &.rise {
      background-color: rgba(255, 0, 32, 0.1);
      color: #ff0020;
    }
    &.fall {
      background-color: rgba(13, 171, 98, 0.1);
      color: #0dab62;
    }
  }
}
</style>
